import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription, Observable } from 'rxjs';
import { Carte } from '../../models/collections/carte.model';
import { CartesService } from '../../services/collections/cartes.service';
import { map, takeUntil } from 'rxjs/operators';
import { refreshDataTable } from '../../helpers/datatables.helper';
import { AlertService } from '../../services/alert.service';
import { NavigationService } from '../../services/navigation.service';
import { I18nService } from '../../services/i18n.service';
import { Post } from '../../models/collections/post.model';
import { PostsService } from '../../services/collections/posts.service';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../services/settings.service';
import { Language } from '../../models/language.model';
import { CurrentUserService } from '../../services/current-user.service';

@Component({
  selector: 'fa-posts-list',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  constructor(
    private sessions: CartesService,
    private categories: PostsService,
    private alert: AlertService,
    private i18n: I18nService,
    private route: ActivatedRoute,
    public navigation: NavigationService,
    public currentUser: CurrentUserService,
    private settings: SettingsService
  ) { }

  ngOnInit() {
   
  }

  ngOnDestroy() {

  }


}
