import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { User, UserRole } from '../models/collections/user.model';
import { CurrentUserService } from '../services/current-user.service';
import { ConfigService } from '../services/collections/config.service';

@Injectable()
export class MaintenanceGuard implements CanActivate {

  constructor(private currentUser: CurrentUserService, private navigation: NavigationService, private config: ConfigService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const user: User = await this.currentUser.get();
      const maintenanceEnable = await this.config.isMaintenanceEnabled();

      if(maintenanceEnable) {
      if (user.role === UserRole.Administrator) {
          resolve(true);
      } else {
          this.navigation.redirectTo('maintenance');
          resolve(false);
      }
    }
      else {
        resolve(true);
      }
    });
  }

}
