import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { User, UserRole } from '../../../models/collections/user.model';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../../services/collections/users.service';
import { Subscription, Subject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PostsService } from '../../../services/collections/posts.service';
import { Post, PostStatus } from '../../../models/collections/post.model';
import { Language } from '../../../models/language.model';
import { SettingsService } from '../../../services/settings.service';
import { Category } from '../../../models/collections/category.model';
import { CategoriesService } from '../../../services/collections/categories.service';
import { PagesService } from '../../../services/collections/pages.service';
import { I18nService } from '../../../services/i18n.service';
import { AlertService } from '../../../services/alert.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { firestore } from 'firebase';

@Component({
  selector: 'fa-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.css']
})
export class UsersProfileComponent implements OnInit, OnDestroy {

  user: User;
  allRoles: object = {};
  latestPosts: Observable<Post[]>;
  postsLanguage: string;
  languages: Language[];
  allPostsStatus: { labels: object, colors: object };
  allPostsCategories: Category[] = [];
  private subscription: Subscription = new Subscription();
  private routeParamsChange: Subject<void> = new Subject<void>();
  private postsLanguageChange: Subject<void> = new Subject<void>();
  statistics: { posts?: number, publishedPosts?: number, comments?: number, pages?: number } = {};

  stripeAccount:any = []
  resultBankLink:any
  resultBank:any = {charges_enabled:false, requirements: {disabled_reason:''}}
  loading:boolean = true


  constructor(
    public navigation: NavigationService,
    private users: UsersService,
    private posts: PostsService,
    private categories: CategoriesService,
    private settings: SettingsService,
    private route: ActivatedRoute,
    private pages: PagesService,
    private currentUser: CurrentUserService,
    private alert: AlertService,
    private i18n: I18nService,
  ) { }

  ngOnInit() {
    // Get all roles
    this.allRoles = this.users.getAllRoles();
    // Get languages
    this.languages = this.settings.getActiveSupportedLanguages();
    this.postsLanguage = '*';//this.languages[0].key;
    // Get all posts status
    this.allPostsStatus = this.posts.getAllStatusWithColors();
    // Get all posts categories
    this.subscription.add(
      this.categories.getAll().pipe(map((categories: Category[]) => {
        const allCategories: Category[] = [];
        categories.forEach((category: Category) => {
          allCategories[category.id] = category;
        });
        return allCategories;
      })).subscribe((categories: Category[]) => {
        // console.log(categories);
        this.allPostsCategories = categories;
      })
    );
    // Get user data
    this.subscription.add(
      this.route.params.subscribe((params: { id: string, account:string }) => {
        // console.log(params);

        if(params.account === "bankValid") {
          this.alert.success(this.i18n.get('UserBankAccountOK'), false, 7000, true);
        }
        if(params.account === "bankCancel") {
          this.alert.error(this.i18n.get('UserBankAccountCancel'), false, 7000, true);
        }

        this.routeParamsChange.next();
        this.postsLanguageChange.next(); // trigger language change too
        this.users.get(params.id).pipe(
          map((user: User) => {
            user.avatar = this.users.getAvatarUrl(user.avatar as string);
            return user;
          }),
          takeUntil(this.routeParamsChange)
        ).subscribe(async(user: User) => {
          // console.log(user);
          if (user) {
            this.user = user;
            this.user.id = params.id;
            this.stripeAccount = user.stripe

            // Get statistics
            this.getStatistics();
            // Get latest posts
            this.getLatestPosts();

            if(this.stripeAccount !== null && this.stripeAccount !== undefined) {
              await this.getBankAccount()
            } else {
              this.loading = false
              this.stripeAccount = null
            }

            this.loading = false

          } else {
            this.navigation.redirectTo('users', 'list');
            this.loading = false
          }
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.routeParamsChange.next();
    this.postsLanguageChange.next();
  }

  private getLatestPosts() {
    this.latestPosts = this.posts.getWhereFn(ref => {
      let query: any = ref;
      //query = query.where('createdBy', '==', this.user.id);
      query = query.where(firestore.FieldPath.documentId(), 'in', this.user.places);
      // Filter by lang
      if (this.postsLanguage !== '*') {
        query = query.where('lang', '==', this.postsLanguage);
      }
      // orderBy & limit requires a database index to work with the where condition above
      // as a workaround, they were replaced with client side sort/slice functions below
      // query = query.orderBy('createdAt', 'desc');
      // query = query.limit(5);
      return query;
    }, true).pipe(
      map((posts: Post[]) => {
        // console.log(posts);
        return posts.sort((a: Post, b: Post) => b.createdAt - a.createdAt).slice(0, 5);
      }),
      takeUntil(this.postsLanguageChange)
    );
  }

  onPostsLanguageChange() {
    this.postsLanguageChange.next();
    this.getLatestPosts();
  }

  private async getStatistics() {
    if (this.user && this.user.id) {
      this.statistics.posts = await this.posts.countWhere(firestore.FieldPath.documentId(), 'in', this.user.places); //countWhere('createdBy', '==', this.user.id);
      const publishedPosts = await this.posts.countWhereFn(ref => ref.where(firestore.FieldPath.documentId(), 'in', this.user.places).where('status', '==', PostStatus.Published)); //where('createdBy', '==', this.user.id)
      this.statistics.publishedPosts = Math.round((publishedPosts / this.statistics.posts) * 100);
      this.statistics.comments = 0; // ToDo
      this.statistics.pages = await this.pages.countWhere('createdBy', '==', this.user.id);
    }
  }

  canEditProfile() {
    return !this.currentUser.isGuest();
  }

  
  async sendBankAccountLink() {
    this.loading = true
    const result = await this.users.getBankAccountLink(this.stripeAccount.id, this.user.id).then((result) => {
      console.log('RESULT OF ACCOUNT LINK', result)
      this.resultBankLink = result.data
    })
    this.loading = false
  }

  async getBankAccount() {
    const result = await this.users.getBankAccount(this.stripeAccount.id).then((result) => {
      console.log('USER INFO BANK ACCOUNT', result)
      this.resultBank = result.data
    })
  }

  async removeBankAccount() {
    this.loading = true
    const result = await this.users.removeBankAccount(this.stripeAccount.id, this.user.id).then((result) => {
      console.log('REMOVE BANK ACCOUNT', result)
      if(result.data.deleted) {
        this.stripeAccount = null
        this.resultBank = {charges_enabled:false, requirements: {disabled_reason:''}}
        this.alert.success(this.i18n.get('UserBankAccountRemoved'), false, 5000, true);
      }
    })
    this.loading = false
  }

  async createBankAccount() {
    this.loading = true
    const newAccount = {email:this.user.email,uid:this.user.id}
    const result = await this.users.createBankAccount(newAccount).then((result) => {
      console.log('CREATE USER BANK ACCOUNT', result)
      this.stripeAccount = result.data
      this.resultBank = result.data
    })
    this.loading = false
  }

}
