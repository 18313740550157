import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';
import { initDropdown, toggleSidebar } from '../../../helpers/layout.helper';
import { getLogo } from '../../../helpers/assets.helper';
import { CurrentUserService } from '../../../services/current-user.service';
import { SidebarItem } from '../../../models/sidebar-item.model';

@Component({
  selector: 'fa-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @Input() style: string = 'expanded';
  isMenuEnabled:boolean = false
  logo: string = getLogo();
  items: SidebarItem[] = [
    // Dashboard
    {
      label: 'Dashboard',
      icon: '&#xE917;',
      routerLink: this.navigation.getRouterLink('dashboard')
    },

    // Sessions
    //{
    //  label: 'Sessions',
    //  icon: 'description',
    //  isActive: this.isActive(['sessions', 'list'], ['sessions', 'add'], ['sessions', 'edit'], ['sessions', 'translate'], ['sessions', 'categories']),
    //  isHidden: () => !this.currentUser.isAdmin(),
    //  childrens: [
    //    {
    //      label: 'List',
    //      routerLink: this.navigation.getRouterLink('sessions', 'list')
    //    },
        /*{
          label: 'Add',
          routerLink: this.navigation.getRouterLink('sessions', 'add')
        },
        {
          label: 'Categories',
          routerLink: this.navigation.getRouterLink('sessions', 'categories')
        }*/
    //  ]
    //},


    // Posts
    {
      label: 'Posts',
      icon: 'description',
      isActive: this.isActive(['posts', 'list'], ['posts', 'add'], ['posts', 'edit'], ['posts', 'translate'], ['posts', 'categories']),
      isHidden: () => !this.currentUser.isAdmin(),
      childrens: [
        {
          label: 'List',
          routerLink: this.navigation.getRouterLink('posts', 'list')
        },
        {
          label: 'Add',
          routerLink: this.navigation.getRouterLink('posts', 'add'),
        },
        {
          label: 'Categories',
          routerLink: this.navigation.getRouterLink('posts', 'categories')
        },
        {
          label: 'Tarifs',
          routerLink: this.navigation.getRouterLink('posts', 'categories-tarifs')
        }
      ]
    },

    


      // Partners
      {
        label: 'Publicités / Partenaires',
        icon: 'description',
        isActive: this.isActive(['partners', 'list'], ['partners', 'add'], ['partners', 'edit'], ['partners', 'translate'], ['partners', 'categories']),
        isHidden: () => !this.currentUser.isAdmin(),
        childrens: [
          {
            label: 'List',
            routerLink: this.navigation.getRouterLink('partners', 'list')
          },
          {
            label: 'Add',
            routerLink: this.navigation.getRouterLink('partners', 'add')
          },
          {
            label: 'Categories',
            routerLink: this.navigation.getRouterLink('partners', 'categories')
          }
        ]
      },

      // Promotions
      {
        label: 'Promo / Invitations',
        icon: 'description',
        isActive: this.isActive(['promotions', 'list'], ['promotions', 'add'], ['promotions', 'edit'], ['promotions', 'translate'], ['promotions', 'categories']),
        isHidden: () => !this.currentUser.isAdmin(),
        childrens: [
          {
            label: 'List',
            routerLink: this.navigation.getRouterLink('promotions', 'list')
          },
          {
            label: 'Add',
            routerLink: this.navigation.getRouterLink('promotions', 'add')
          },
          {
            label: 'Categories',
            routerLink: this.navigation.getRouterLink('promotions', 'categories')
          }
        ]
      },


      // Notifications
    {
      label: 'Notifications Push',
      icon: 'description',
      isActive: this.isActive(['notifications', 'list'], ['notifications', 'add'], ['notifications', 'edit'], ['notifications', 'translate'], ['notifications', 'categories']),
      isHidden: () => !this.currentUser.isAdmin(),
      childrens: [
        {
          label: 'List',
          routerLink: this.navigation.getRouterLink('notifications', 'list')
        },
        {
          label: 'Add',
          routerLink: this.navigation.getRouterLink('notifications', 'add')
        },
        {
          label: 'Categories',
          routerLink: this.navigation.getRouterLink('notifications', 'categories')
        }
      ]
    },

    
    // Devices
    {
      label: 'Appareils connectés',
      icon: 'description',
      isActive: this.isActive(['devices', 'list'], ['devices', 'add'], ['devices', 'edit'], ['devices', 'translate'], ['devices', 'categories']),
      isHidden: () => !this.currentUser.isAdmin(),
      childrens: [
        {
          label: 'List',
          routerLink: this.navigation.getRouterLink('devices', 'list')
        },
        /*{
          label: 'Add',
          routerLink: this.navigation.getRouterLink('cartes', 'add')
        },
        {
          label: 'Categories',
          routerLink: this.navigation.getRouterLink('cartes', 'categories')
        }*/
      ]
    },
    
    // Users
    {
      label: 'Users',
      icon: 'person',
      isActive: this.isActive(['users', 'list'], ['users', 'add'], ['users', 'edit'], ['users', 'profile']),
      isHidden: () => !this.currentUser.isAdmin(),
      childrens: [
        {
          label: 'List',
          routerLink: this.navigation.getRouterLink('users', 'list')
        },
        {
          label: 'Add',
          routerLink: this.navigation.getRouterLink('users', 'add')
        }
      ]
    },

     // Customers
     {
      label: 'Clients',
      icon: 'description',
      isActive: this.isActive(['customers', 'list']),
      isHidden: () => !this.currentUser.isAdmin(),
      childrens: [
        {
          label: 'List',
          routerLink: this.navigation.getRouterLink('customers', 'list')
        },
        /*{
          label: 'Add',
          routerLink: this.navigation.getRouterLink('cartes', 'add')
        },
        {
          label: 'Categories',
          routerLink: this.navigation.getRouterLink('cartes', 'categories')
        }*/
      ]
    },


    // Pages
    {
      label: 'Pages',
      icon: 'insert_drive_file',
      isActive: this.isActive(['pages', 'list'], ['pages', 'add'], ['pages', 'edit'], ['pages', 'translate']),
      isHidden: () => !this.currentUser.isAdmin(),
      childrens: [
        {
          label: 'List',
          routerLink: this.navigation.getRouterLink('pages', 'list')
        },
        {
          label: 'Add',
          routerLink: this.navigation.getRouterLink('pages', 'add')
        }
      ]
    },
    
    // Translations
    /*{
      label: 'Translations',
      icon: 'language',
      routerLink: this.navigation.getRouterLink('translations'),
      isHidden: () => !this.currentUser.isAdmin(),
    },*/
    // {
    //   label: 'Menus',
    //   icon: 'menu',
    //   routerLink: this.navigation.getRouterLink('menus')
    // },
    // {
    //   label: 'Comments',
    //   icon: 'comment',
    //   isActive: this.isActive(['comments', 'list'], ['comments', 'add'], ['comments', 'edit']),
    //   childrens: [
    //     {
    //       label: 'List',
    //       routerLink: this.navigation.getRouterLink('comments', 'list')
    //     },
    //     {
    //       label: 'Add',
    //       routerLink: this.navigation.getRouterLink('comments', 'add')
    //     }
    //   ]
    // },
    // {
    //   label: 'Media',
    //   icon: '&#xE251;',
    //   isActive: this.isActive(['media', 'list'], ['media', 'add'], ['media', 'edit']),
    //   childrens: [
    //     {
    //       label: 'List',
    //       routerLink: this.navigation.getRouterLink('media', 'list')
    //     },
    //     {
    //       label: 'Add',
    //       routerLink: this.navigation.getRouterLink('media', 'add')
    //     }
    //   ]
    // },
  ];
 
  constructor(public navigation: NavigationService, private currentUser: CurrentUserService) { }


  ngOnInit() {
    if(this.currentUser.isAdmin()) {
      this.isMenuEnabled = true
    }
  }

  ngAfterViewInit() {
    initDropdown();
  }

  private isRouteActive(...path: string[]) {
    const link = this.navigation.getRouterLink(...path).join('/');
    //console.log(link);
    return this.navigation.router.isActive(link, false);
  }

  private isActive(...routes: any[]) {
    let isActive = false;
    routes.forEach((path: string[]) => {
      if (this.isRouteActive(...path)) {
        isActive = true;
      }
    });
    return isActive;
  }

  toggle() {
    toggleSidebar();
  }

}
