
export const en = {

  "kTDuHkwA3vDuf7zQ1sOX":"Patinoire de Cluses",
  "bsVKT3aQaP2abjtqz6Cx":"Patinoire de Belfort",
  "E9B0tKVEz5oUBdJv0eaO":"Patinoire de Montbéliard",
  "WkO4yJsu5lDAADFAx656":"Châteauroux",
  
  // Login page
  "SignInToYourAccount": "Sign In to Your Account",
  "EmailAddress": "Email address",
  "EnterEmail": "Enter email",
  "Password": "Password",
  "RememberMe": "Remember me",
  "SignIn": "Sign In",
  "ForgotYourPassword": "Forgot your password?",
  // Register page
  "WelcomeToFireAdmin": "Welcome To FireAdmin",
  "RepeatPassword": "Repeat Password",
  "CreateAccount": "Create Account",
  // Sidebar
  "Dashboard": "Dashboard",
  "Pages": "Pages",
  "List": "List",
  "Add": "Add",
  "Posts": "Posts",
  "Sessions": "Trafic in real time",
  "Cartes":"cards sold",
  "Categories": "Categories",
  "Translations": "Translations",
  "Comments": "Comments",
  "Menus": "Menus",
  "Media": "Media",
  "Users": "Users",
  // Navbar
  "AddPost": "Add post",
  "Profile": "Profile",
  "Settings": "Settings",
  "Logout": "Logout",
    //Resume
    "OrdersByPlace": "Orders by place",
    "OrdersByDate": "Orders by date",
  //Customers
  "ListCustomers":"Customers list",
  // Dashboard
  "PostsByStatus": "Posts By Status",
  "ChartsSold": "Cards sold",
  "ChartsUsed": "Used cards",
  // Pages: Add
  "AddNewPage": "Add New Page",
  "PageTitle": "Page Title",
  "PageSlug": "Slug",
  "PageLanguage": "Language",
  "PageBlock": "Block",
  "PageBlocks": "Blocks",
  "AddPage": "Add Page",
  "AddBlock": "Add Block",
  "BlockName": "Name",
  "BlockType": "Type",
  "BlockContent": "Content",
  "RemoveBlock": "Remove",
  "CollapseBlock": "Collapse",
  "PageAdded": "Page successfully added!",
  "PageSlugAlreadyExists": "Page slug already exists! Choose another one.",
  // Pages: Block types
  "Text": "Text",
  "HTML": "HTML",
  "JSON": "JSON",
  // Pages: Edit
  "EditPage": "Edit Page",
  "PageSaved": "Page successfully saved!",
  "UserBankAccountRemoved": "The Stripe Pro account and associated bank accounts are deleted.",
  "UserBankAccountOK": "Successful transaction from the Stripe payment provider.",
  "UserBankAccountCancel": "Transaction refused by the payment provider Stripe.",
  "PostUsersList":"Users list of the place",
  // Pages: Translate
  "TranslatePage": "Translate Page",
  // Pages: List
  "PageCreatedAt": "Creation date",
  "PageUpdatedAt": "Last Modification date",
  "PageAuthor": "Author",
  "DeletePage": "Delete Page",
  "ConfirmDeletePage": "Please confirm deleting page <b>${title}</b> ?",
  "PageDeleted": "Page <b>${title}</b> has been deleted!",

  // Sessions
  "SessionNumberPass": "PASS number",
  "SessionName": "Session",
  "SessiononIce": "On ice",
  "SessionType": "Type",
  "SessionsPersonnes": "People",
  "SessionsOptions": "Options",
  "SessionsByStatus": "Trafic by rink",
  "SessionOrderByRink": "Order by rink",
  "SessionSince":"Since",
  "SessionPlace":"Location",
  "SessionDescription":"Description",
  "SessionPrice": "Price",
  "SessionCreateAt": "Create at",
  "SessionRegister": "Connected",

   // Cartes
   "cardSold":"cards sold",
   "cardValid":"valid cards",
   "appInstall":"PASS install",

  // Posts: Add
  "AddNewPost": "Add New Post",
  "PostTitle": "Post Title",
  "PostContent": "Post Content",
  "Actions": "Actions",
  "PostLanguage": "Language",
  "PostStatus": "Status",
  "PostDate": "Date",
  "PostImage": "Image",
  "PostSlug": "Slug",
  "PostEmail": "Email",
  "PostPhone": "Phone",
  "SaveDraft": "Save Draft",
  "Publish": "Publish",
  "MoveToTrash": "Move To Trash",
  "PostAdded": "Post successfully added!",
  "PostSlugAlreadyExists": "Post slug already exists! Choose another one.",
  "PostContact": "Contact",
  "PostBank": "Compte bancaire de l\'établissement",
  // Posts: Status
  "Draft": "Draft",
  "Published": "Published",
  "Trash": "Trash",
  // Posts: List
  "PostAll":"All rinks",
  "PostCreatedAt": "Date creation",
  "PostCreatedAtShort": "create on",
  "PostUpdatedAt": "Last Modification date",
  "PostUpdatedAtShort": "edit on",
  "PostAuthor": "Author",
  "Translate": "Translate",
  "DeletePost": "Delete Post",
  "ConfirmDeletePost": "Please confirm deleting post <b>${title}</b> ?",
  "PostDeleted": "Post <b>${title}</b> has been deleted!",
  // Posts: Edit
  "EditPost": "Edit Post",
  "PostSaved": "Post successfully saved!",
  // Posts: Translate
  "TranslatePost": "Translate Post",
  // Languages
  "All": "All",
  "English": "English",
  "French": "French",
  "Arabic": "Arabic",
  // Settings
  "General": "General",
  "Internationalization": "Internationalization",
  "Language": "Language",
  "SidebarStyle": "Sidebar style",
  "Expanded": "Expanded",
  "Collapsed": "Collapsed",
  "HeaderBar": "Header bar",
  "SaveChanges": "Save Changes",
  "SettingsSaved" : "Settings successfully saved!",
  "SupportedLanguages" : "Supported languages",
  "Key": "Key",
  "Remove": "Remove",
  "Cancel": "Cancel",
  // Categories
  "NewCategory": "New Category",
  "CategoryLabel": "Label",
  "CategorySlug": "Slug",
  "CategoryLanguage": "Language",
  "AddCategory": "Add Category",
  "CategoryAdded": "Category successfully added!",
  "DeleteCategory": "Delete Category",
  "ConfirmDeleteCategory": "Please confirm deleting category <b>${label}</b> ?",
  "CategoryDeleted": "Category <b>${label}</b> has been deleted!",
  "EditCategory": "Edit Category",
  "CategorySaved": "Category <b>${label}</b> has been saved!",
  // Lists
  "Edit": "Edit",
  "Delete": "Delete",
  "Save": "Save",
  // Users: Add
  "AddNewUser": "Add New User",
  "AddUser": "Add User",
  "NewUser": "New User",
  "ListUsers":"Users list",
  "FirstName": "First Name",
  "LastName": "Last Name",
  "Email": "Email",
  "PlaceEmployee": "Rink's employee",
  "BirthDate": "Birth Date",
  "Role": "Role",
  "Bio": "Bio",
  "BioPlaceholder": "I'm a design focused engineer.",
  "ProfilePicture": "Profile Picture",
  "UploadImage": "Upload Image",
  "UserAdded": "User successfully added!",
  // Users: Edit
  "EditUser": "Edit User",
  "UserDetails": "User Details",
  "UpdateUser": "Update User",
  "UserUpdated": "User successfully updated!",
  // Users: Profile
  "UserProfile": "User Profile",
  "LatestPosts": "Latest Posts",
  "LatestSessions": "Real time traffic",
  "RapportSessions": "History",
  "FullList": "Full List",
  "RapportFullList": "Complete history",
  // Users: List
  "UserName": "User name",
  "CreatedAt": "Creation date",
  "UpdatedAt": "Last modification date",
  "CreatedBy": "Created by",
  "DeleteUser": "Delete User",
  "ConfirmDeleteUser": "Please confirm deleting user <b>${name}</b> ?",
  "UserDeleted": "User <b>${name}</b> has been deleted!",
  "UserChoicePlace": "Choice a rink",
  "UserBank": "Bank account",
  // Users: Roles
  "Administrator": "Administrator",
  "Regisseur": "manager",
  "Editor": "Editor",
  "Author": "Author",
  "Contributor": "Contributor",
  "Employee": "Rink employee",
  "EmployeeCaisse": "Rink cashier",
  "Guest": "Guest",
  "Vendor":"Vendeur dans réseau",
  // Translations
  "NewTranslation": "New Translation",
  "TranslationKey": "Key",
  "TranslationValue": "Value",
  "TranslationLanguage": "Language",
  "AddTranslation": "Add Translation",
  "TranslationAdded": "Translation successfully added!",
  "DeleteTranslation": "Delete Translation",
  "ConfirmDeleteTranslation": "Please confirm deleting translation <b>${key}</b> ?",
  "TranslationDeleted": "Translation <b>${key}</b> has been deleted!",
  "EditTranslation": "Edit Translation",
  "TranslationSaved": "Translation <b>${key}</b> has been saved!",
  "TranslationKeyAlreadyExists": "Translation key <b>${key}</b> already exists for language <b>${lang}</b>.",
  "TranslationsTooltip": "Translations can be used with <a href=\"http://www.ngx-translate.com/\" target=\"_blank\">ngx-translate</a> library, for any repetitive text (on header/footer or breadcrumb) that needs to be translated.",
  // Error messages
  "GuestsAreNotAllowedToPerformChanges": "Guest users are not allowed to perform changes.",
};
