import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private valueSubject = new Subject<any>();

  emitValue(value: any) {
    this.valueSubject.next(value);
  }

  getValue$() {
    return this.valueSubject.asObservable();
  }
}
